<script setup lang="ts">
import { NuxtLocale } from '~/constants/locales'

const props = withDefaults(
  defineProps<{
    contentCode: string
    sectionClass?: string
    containerClass?: string
    textContainerClass?: string
    showTitle?: boolean
    titleContainerClass?: string
    backgroundImage?: boolean
    showImages?: boolean
    renderWholeSection?: boolean
  }>(),
  {
    sectionClass: 'relative',
    containerClass: 'container mx-auto',
    textContainerClass: 'content-block',
    showTitle: true,
    titleContainerClass: '',
    backgroundImage: false,
    showImages: true,
    renderWholeSection: false,
  }
)

const { locale } = useI18n()
const { getResourceUrl } = useResources()

const { data: contentBlock, status } = await useLazyAsyncData(props.contentCode, () =>
  createContentBlocksRepository().getByCode(
    props.contentCode,
    convertNuxtLocale(locale.value as NuxtLocale)
  )
)
</script>

<template>
  <div>
    <div v-if="status === 'pending'">
      <UiSkeletonContentBlock />
    </div>
    <div v-else>
      <div v-if="renderWholeSection" v-html="contentBlock?.content"></div>
      <section v-else :class="sectionClass">
        <img
          v-if="backgroundImage"
          class="lazy pointer-events-none absolute left-0 top-0 block h-full w-full object-cover opacity-100"
          :src="getResourceUrl(contentBlock?.images[0].url + '/1280x1280/FIT')"
          :alt="contentBlock?.images[0].metaAlt ?? undefined"
          :title="contentBlock?.images[0].metaTitle ?? undefined"
          loading="lazy"
        />
        <div v-if="contentBlock" class="relative">
          <div :class="containerClass">
            <div class="flex flex-wrap items-end">
              <div :class="textContainerClass">
                <h2
                  v-if="showTitle"
                  class="font-pragmatica-ext mb-8 text-4xl font-bold leading-[1]"
                  :class="titleContainerClass"
                >
                  {{ contentBlock?.title }}
                </h2>
                <div v-html="contentBlock?.content" />
              </div>
              <UiYoutubePlayer
                v-for="video in contentBlock?.videos"
                :key="video?.nid"
                :poster="
                  getResourceUrl(video?.posterImage?.url + '/1280x1280/FIT')
                "
                :video-id="video?.videoId"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!backgroundImage && showImages && contentBlock?.images?.length"
          class="pt-16"
        >
          <UiGalleryCarousel :images="contentBlock?.images" />
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped></style>
