import type { QueryParams } from '~/types/api'
import type { ContentBlock, ContentBlockPagedResult } from '~/types/api'
import { ApiLocale } from '~/constants/locales'

export function createContentBlocksRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'content-blocks'

  async function getAll(params?: QueryParams) {
    return get<ContentBlockPagedResult>([DOMAIN], { params })
  }

  async function getByNid(nid: number) {
    return get<ContentBlock>([DOMAIN, nid.toString()])
  }

  async function getByCode(code: string, language: ApiLocale) {
    return get<ContentBlock>([DOMAIN, code], {
      params: { language },
    })
  }

  return {
    getAll,
    getByNid,
    getByCode,
  }
}
